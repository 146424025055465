import React from 'react';

export default function UnlimitedQuota({ feature, plan, quota })
{

    return (
        
        <React.Fragment>
            <p>
                <React.Fragment>
                    <strong>Unlimited</strong>
                </React.Fragment>
            </p>
        </React.Fragment>
        
    );

}