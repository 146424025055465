import React from 'react';

import { plans } from '../../../data/plans';

import Feature from './feature';
import FeatureGroupHeader from './featureGroupHeader';

const renderDisabled = false;

export default function FeatureGroup({ featureGroup })
{

    return (

        <React.Fragment>

            {!featureGroup.hideTitle &&

                <FeatureGroupHeader featureGroup={featureGroup} />

            }
        
            {featureGroup.features.map(function(feature) {

                if(!renderDisabled || !feature.disabled)
                {

                    return <Feature featureGroup={featureGroup} feature={feature} />;

                }                

            })}

        </React.Fragment>
    );

}