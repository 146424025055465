import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function RedCross({ size }) {

    return (
        <FontAwesomeIcon icon={faTimesCircle} style={{color: '#f03e3e'}} title="N" size={size}  />
    );

};