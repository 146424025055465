import React from 'react';

import { plans } from '../../../data/plans';

export default function FeatureGroupHeader({ featureGroup })
{

    return (
        <tr>
            <td colSpan={plans.length+1} className="text-left">
                <span style={{fontWeight: 700}} className="h4 text-uppercase text-primary">{featureGroup.title}</span>
                <div className="row plan-short-description">
                    <div className="col-sm-12">
                        <span>{featureGroup.description}</span>
                    </div>
                </div>   
            </td>
        </tr>
    );

}