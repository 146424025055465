import { Link } from "gatsby";
import React from "react";

import { plans } from '../../data/plans';

import { featureGroups} from '../../data/features';
import FeatureGroup from "./FeatureGroup";

export default function PricingComparisionTable() {

    return (
        <table className="table table-bordered pricing pricing-comparision-table">        
            <thead>
                <tr>
                    <th className="plan-name-column"></th>
                    {plans.map(function(plan) {

                        return <th className="text-center package-type h4">{plan.title}</th>;

                    })}
                </tr>
            </thead>
            <tbody>
                {featureGroups.map(function(featureGroup) {

                    return <FeatureGroup featureGroup={featureGroup} />;

                })}
            
            </tbody>
        </table>
    );

};