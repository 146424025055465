import React from 'react';

import GreenTick from "../GreenTick";
import PlanQuotaInfo from "../PlanQuotaInfo";
import RedCross from "../RedCross";

import FeatureQuotaInfo from "../FeatureQuotaInfo";

import { plans } from '../../../data/plans';

import { FEATURE_TYPE_QUOTA_BASED, FEATURE_TYPE_FEATURE } from '../../../data/features';

export default function Feature({ featureGroup, feature })
{

    return (
        <tr>
            <td className="text-left">
                <span style={{fontWeight: 700}} className="h4">{feature.title}</span>
                <div className="row plan-short-description text-left">
                    <div className="col-sm-12">
                        <div>
                            <span>{feature.description}</span>
                        </div>
                    </div>
                </div>   
            </td>
            {plans.map(function(plan) {

                const planHasFeature = feature.plansAvailableFor.includes(plan.planKey);            

                return (
                    <td>

                        {planHasFeature ?

                            <React.Fragment>

                                {feature.featureType == FEATURE_TYPE_QUOTA_BASED && 
                                
                                    <FeatureQuotaInfo feature={feature} plan={plan} />

                                }

                                {feature.featureType == FEATURE_TYPE_FEATURE &&

                                    <React.Fragment>

                                        <GreenTick size={'2x'} />

                                    </React.Fragment>                                                       

                                }

                            </React.Fragment>

                            :

                            <React.Fragment>
                        
                                <RedCross size={'2x'} />

                            </React.Fragment>

                        }

                    </td>
                );

            })}
            
        </tr>
    );

}
