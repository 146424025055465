import * as React from "react";
import Pricing from "../components/Pricing";
import WebsiteLayout from "../layouts/WebsiteLayout";

import RegisterCta from '../components/RegisterCta';
import PricingComparisionTable from "../components/PricingComparisionTable";

const PricingPage = (props) => {
  return (
    <main>
        <WebsiteLayout location={props.location} title="Pricing">
        
            <section className="content-header content-header-1 -blue">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="heading">Pricing</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pricing pricing-3">
                <Pricing />
            </section>

            <section className="content content-6" id="comparisionTable">
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="heading">What are the differences?</h1>

                            <PricingComparisionTable />

                        </div>
                    </div>
                </div>
            </section>

        <RegisterCta />

        </WebsiteLayout>
    </main>
  );
}

export default PricingPage;