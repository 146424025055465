import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function GreenTick({ size }) {

    return (
        <FontAwesomeIcon icon={faCheckCircle} style={{color: '#26c031'}} title="Y" size={size} />
    );

};