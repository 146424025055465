import React from 'react';

export default function User({ feature, plan, quota, unlimited }) {

    const isPlural = quota.quotaAmount > 1;

    if(unlimited)
    {

        return (
        
            <React.Fragment>
                <p>
                    <React.Fragment>
                        <strong>Unlimited</strong> Users
                    </React.Fragment>
                </p>
            </React.Fragment>
            
        );

    }

    return (

        <React.Fragment>

            <p><strong>{quota.quotaAmount}</strong>
            { isPlural &&
                <React.Fragment> Users</React.Fragment>
            }
            {!isPlural &&
                <React.Fragment> User</React.Fragment>
            }
            </p>

        </React.Fragment>

    );

};