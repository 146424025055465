import React from 'react';
import User from './User';
import Employee from './Employee';
import UnlimitedQuota from './UnlimitedQuota';

import { QUOTA_TYPE_NUMBER_EMPLOYEES, QUOTA_TYPE_NUMBER_USERS, QUOTA_TYPE_UNLIMITED_USERS, QUOTA_TYPE_UNLIMITED } from '../../../data/features';

export default function FeatureQuotaInfo({ feature, plan }) {

    const quota = feature.planQuotas.filter(function(item) {

        return item.planKey == plan.planKey;

    })[0];

    if(!quota) return null;

    return (

        <React.Fragment>

            {(quota.quotaType == QUOTA_TYPE_NUMBER_USERS) &&
            
                <User feature={feature} plan={plan} quota={quota} />

            }

            {(quota.quotaType == QUOTA_TYPE_UNLIMITED_USERS) &&
            
                <User feature={feature} plan={plan} quota={quota} unlimited={true} />

            }

            {(quota.quotaType == QUOTA_TYPE_NUMBER_EMPLOYEES) &&
            
                <Employee feature={feature} plan={plan} quota={quota} />

            }

            {(quota.quotaType == QUOTA_TYPE_UNLIMITED) &&
                
                <UnlimitedQuota feature={feature} plan={plan} quota={quota} />

            }

            {/* {quota.quotaAmount} */}

        </React.Fragment>

    );

};